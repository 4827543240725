<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2" lg="2"></v-col>
      <v-col cols="12" md="8" lg="8">
        <v-card elevation="8" class="pa-6 mb-6">
          <v-row class="pt-3">
            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{ $t("meeting.form.topic") }}</label>
              <v-text-field v-model="title" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{ $t("meeting.form.dept") }}</label>
              <v-autocomplete
                filled
                dense
                item-text="name"
                item-value="id"
                outlined
                :items="listDepartment"
                hide-selected
                v-model="department_id"
                @change="fetEmployee(department_id)"
              >
                <!-- :hint="`${server_errors.department_id}`" -->
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("meeting.form.attendance")
              }}</label>

              <v-autocomplete
                dense
                item-text="name"
                item-value="id"
                outlined
                :items="listEmployee"
                v-model="employee_id"
                multiple
                chips
                small-chips
                return-object
              >
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="1" lg="1" class="mt-4 d-flex justify-end">
              <v-checkbox
                v-model="all"
                label="All"
                color="secondary"
                value="secondary"
                hide-details
              ></v-checkbox>
            </v-col> -->

            <v-col cols="12" md="12" lg="12" class="mb-3">
              <v-card elevation="2" class="pa-2" v-if="employee_id != null">
                <span v-for="(item, index) in employee_id" :key="index">
                  <v-chip
                    class="ma-2"
                    close
                    small
                    @click:close="removeEmpMeeting(item.name)"
                  >
                    {{ item.name }}
                  </v-chip>
                </span>
              </v-card>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("e_leave.addLeave.startDate")
              }}</label>
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                v-model="start_date"
                @change="genLeaveDate"
                :hint="`${server_errors.startDate}`"
              >
              </DatePicker>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("e_leave.addLeave.endDate")
              }}</label>
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                v-model="end_date"
                @change="genLeaveDate"
                :hint="`${server_errors.endDate}`"
              >
              </DatePicker>
            </v-col>

            <v-col cols="12">
              <v-list shaped>
                <v-list-item-group>
                  <template v-for="(item, i) in meetDate">
                    <v-list-item :key="`item-${i}`">
                      <template dense>
                        <v-list-item-icon style="padding: 6px">
                          <v-icon>mdi-clock</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content @click="addLeaveTime(item)">
                          <v-list-item-title
                            v-text="
                              item.date +
                                ' (' +
                                item.start_time +
                                ' - ' +
                                item.end_time +
                                ')'
                            "
                            v-if="
                              item.start_time != null && item.end_time != null
                            "
                          ></v-list-item-title>

                          <v-list-item-title
                            v-text="item.date"
                            v-else
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            icon
                            color="pink"
                            @click="removeDate(item.date)"
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{
                $t("meeting.form.meetingPlace")
              }}</label>
              <v-textarea
                label="place"
                auto-grow
                outlined
                rows="3"
                row-height="10"
                v-model="place"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{ $t("meeting.form.detail") }}</label>
              <v-textarea
                label="detail"
                auto-grow
                outlined
                rows="5"
                row-height="13"
                v-model="detail"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-card elevation="4" class="pa-4 lfont">
                <v-row>
                  <v-col cols="12">
                    <p>{{ $t("meeting.form.meetingStatus") }}</p>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-radio-group v-model="emphasis_id" column>
                      <v-radio
                        v-for="(item, index) in listEmphasis"
                        :key="index"
                        :color="getColor(item.id)"
                        :value="item.id"
                      >
                        <template v-slot:label>
                          <div :style="{ color: getColor(item.id) }">
                            {{ item.name }}
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" class="pb-4">
              <v-btn
                class="mt-7 btn-create lfont btn"
                color="info"
                @click="saveChange"
              >
                {{ $t("meeting.form.btn_save") }}</v-btn
              >
            </v-col>
            <v-dialog v-model="dialogEditLeaveTime" scrollable width="300">
              <v-card>
                <v-card-title class="lfont">{{
                  $t("e_leave.addLeave.add_time")
                }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <div class="lfont">
                    <h1>{{ $t("e_leave.addLeave.start_time") }}</h1>
                    <v-text-field
                      type="time"
                      v-model="start_time"
                    ></v-text-field>
                    <h1>{{ $t("e_leave.addLeave.end_time") }}</h1>
                    <v-text-field type="time" v-model="end_time"></v-text-field>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    variant="text"
                    class="btn-save"
                    @click="updateDateLeave"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    color="red"
                    variant="text"
                    @click="dialogEditLeaveTime = false"
                    class="btn-cancel"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" lg="2"></v-col>
    </v-row>
    <Loading v-if="isLoading" />
  </v-container>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      emphasis_id: "1",
      title: "",
      detail: "",
      place: "",
      start_time: "",
      end_time: "",
      employee_id: null,
      start_date: "",
      end_date: "",
      dialogEditLeaveTime: false,
      meetDate: [],
      isLoading: false,
      listDepartment: [],
      listEmphasis: [],
      listEmployee: [],
      department_id: "",
      assignee_id: "",
      server_errors: {
        emphasis_id: "",
        title: "",
        place: "",
        employee_id: [],
        detail: "",
        meetDate: [],
      },
    };
  },
  watch: {
    // employee_id(new_value) {
    //   console.log(new_value);
    // },
  },
  methods: {
    getColor(id) {
      if (id == "1") {
        return "red";
      }
      if (id == "2") {
        return "orange";
      }
      if (id == "3") {
        return "green";
      }
    },

    addLeaveTime(item) {
      this.dialogEditLeaveTime = true;
      this.datesLeaveEdit = item;
    },
    updateDateLeave() {
      let date = this.datesLeaveEdit.date;
      let start_time = this.start_time;
      let end_time = this.end_time;
      this.meetDate = this.meetDate.filter(function(items) {
        if (items.date == date) {
          items.start_time = start_time;
          items.end_time = end_time;
        }
        return items;
      });
      this.dialogEditLeaveTime = false;
      this.start_time = "";
      this.end_time = "";
    },
    removeDate(date) {
      this.meetDate = this.meetDate.filter(function(item) {
        return item.date != date;
      });
    },
    removeEmpMeeting(name) {
      this.employee_id = this.employee_id.filter(function(item) {
        return item.name != name;
      });
    },
    genLeaveDate() {
      this.meetDate = [];
      if (this.start_date && this.end_date) {
        var date1 = new Date(this.start_date);
        var date2 = new Date(this.end_date);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        for (var i = 0; i <= Difference_In_Days; i++) {
          var d = new Date(this.start_date);
          d.setDate(date1.getDate() + i);
          d = moment(d).format("YYYY-MM-DD");
          this.meetDate.push({
            date: d,
            start_time: null,
            end_time: null,
          });
        }
      } else {
        this.meetDate = [];
      }
    },

    saveChange() {
      const items = {
        emphasis_id: this.emphasis_id,
        title: this.title,
        place: this.place,
        employees_id: this.employee_id,
        detail: this.detail,
        meetDate: this.meetDate,
      };
      this.$axios
        .post(`/meeting`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.$router
              .push({
                name: "employee_meeting.index",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    fetchDepartment() {
      this.isLoading = true;
      this.$axios
        .get(`/employee/list/departments`)
        .then((res) => {
          // console.log("department", res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listDepartment = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    fetchEmphasis() {
      this.isLoading = true;
      this.$axios
        .get(`/meeting/emphasis`)
        .then((res) => {
          console.log("emphasis", res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmphasis = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    fetEmployee(dept_id) {
      this.isLoading = true;
      this.$axios
        .get(`/meeting/list-employee-name`, {
          params: {
            department_id: dept_id,
          },
        })
        .then((res) => {
          console.log("listEmp", res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployee = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchDepartment();
    this.fetchEmphasis();
  },
};
</script>

<style lang="scss" scoped>
.redbox {
  width: 30px;
  height: 20px;
  background-color: red;
}
.orangebox {
  width: 30px;
  height: 20px;
  background-color: orange;
}
.greenbox {
  width: 30px;
  height: 20px;
  background-color: green;
}
</style>
